import React from 'react'
import Layout from "../components/App/Layout"
import NavbarStaging from "../components/App/NavbarStaging"
import Footer from "../components/App/Footer"
import RoomDetail from '../components/Rooms/RoomDetail'

const RoomTemplate = (props) => {
    return (
        <Layout>
            <NavbarStaging />
            <RoomDetail pageName={props.pageContext.pageName} imagesMap={props.pageContext.imagesMap}/>
            <Footer />
        </Layout>
    );
}

export default RoomTemplate