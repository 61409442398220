import React from 'react'
import {Link} from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import jingxue from '../../assets/images/team/jingxue.jpg'

const RoomDetail = ({ pageName, imagesMap }) => {
    
    const [photoIndex, setPhotoIndex] = React.useState(0);
    const [isOpenImage, setIsOpenImage] = React.useState(false);

    var examples = []
    var i = 1
    for(var property in imagesMap) {
        const images = imagesMap[property]
        const elements = []
        for(var image of images) {
            elements.push(
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="single-gallery-item">
                        <Link 
                            to="#"
                            onClick={e => {
                                e.preventDefault(); 
                                setIsOpenImage(true); 
                                setPhotoIndex(0);}
                            }
                        >
                            <img src={image.url} alt="event" />
                        </Link>
                    </div>
                </div>
            )
        }

        const titleSection = <h2>Example {i}</h2>
        const infoSection = <div className="entry-meta">
                                <ul>
                                    <li>
                                        <i className='bx bx-home'></i>
                                        <span>Category</span>
                                        <Link to="/blog">
                                            Fashion
                                        </Link>
                                    </li>
                                    <li>
                                        <i className='bx bxs-bed'></i>
                                        <span>Sq Ft</span>
                                        <Link to="#">
                                            813,454
                                        </Link>
                                    </li>
                                    <li>
                                        <i className='bx bx-calendar'></i>
                                        <span>Last Updated</span>
                                        <Link to="#">
                                            25/04/2020
                                        </Link>
                                    </li>
                                </ul>
                            </div>
        examples.push(
            <div className="article-content">
                {titleSection}
                {infoSection}
                <div className="row">
                    { elements }
                </div>
            </div>
        )
        i++
    }



    return (
        <section className="blog-details-area ptb-40">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="contact" /> 
                    </span>
                    <h2>{pageName}</h2>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="blog-details-desc">
                            { examples }

                            <div className="article-footer">
                                <div className="article-tags">
                                    <span><i className='bx bx-purchase-tag'></i></span>
                                    <Link to="/blog">Fashion</Link>
                                </div>

                                <div className="article-share">
                                    <ul className="social">
                                        <li><span>Share:</span></li>
                                        <li>
                                            <Link to="https://www.facebook.com/" className="facebook" target="_blank" rel="noreferrer">
                                                <i className='bx bxl-facebook'></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://twitter.com/" className="twitter" target="_blank" rel="noreferrer">
                                                <i className='bx bxl-twitter'></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://www.instagram.com/" className="linkedin" target="_blank" rel="noreferrer">
                                                <i className='bx bxl-instagram'></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="https://www.linkedin.com/" className="instagram" target="_blank" rel="noreferrer">
                                                <i className='bx bxl-linkedin'></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="article-author">
                                <div className="author-profile-header"></div>
                                <div className="author-profile">
                                    <div className="author-profile-title">
                                        <img src={jingxue} className="shadow-sm" alt="uu" />
                                        <h4>Jing Xue</h4>
                                        <span className="d-block">Photographer, Author, Writer</span>
                                        <p>Jing is.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default RoomDetail